import { Earning } from '../model/Earning';
import { Transaction } from '../model/Transaction';
import { PayoutPack, PayoutStore } from '@/model/Payout';
import { Payout } from '@/model/offer';
import { UserEarning } from '@/model/UserStats';
import { apiAxiosV1, apiAxiosV2 } from '@/configs/axios';
import { StorageService } from './StorageService';
import { API_END_POINT_V2_1 } from '@/configs/config';

export const WalletService = {
  async getWalletBalance(wid) {
    const res = await apiAxiosV2.get(`/wallet/${wid}/balance`);
    return res?.data;
  },
  async getTotalCoinsEarned(wid) {
    const res = await apiAxiosV2.get(`/wallet/${wid}/total`);
    return res?.data?.total_earned?.amount;
  },
  async getMinimumPayout(wid) {
    const res = await apiAxiosV2.get(`/wallet/${wid}/minimum_payout`);
    return res?.data;
  },
  async getEarningHistory({ wid, page = 1 }) {
    const res = await apiAxiosV2.get(
      `/wallet/${wid}/transactions?page=${page}`
    );

    return Earning.fromAll(res?.data);
  },
  async getTransactionHistory({ wid, page = 1 }) {
    const res = await apiAxiosV2.get(`/transaction/${wid}/list?page=${page}`);
    return Transaction.fromAll(res?.data);
  },
  async getTransactionDetails({ tid }) {
    const res = await apiAxiosV1.get(
      API_END_POINT_V2_1 + `/transaction/get?transaction_id=${tid}`
    );

    return Transaction.from(res?.data);
  },
  async getTotalINREarned() {
    const res = await apiAxiosV1.get('/transaction/total_inr/me');
    return res?.data;
  },
  async redeemWallet(data, wid) {
    const payload = {
      vpa: data.vpa,
      paytm_phone_number: data.paytm_phone_number,
      payment_gateway: data.payment_gateway,
      payment_method: data.payment_method,
    };
    const res = await apiAxiosV1.post(`/wallet/${wid}/redeem`, payload);
    return res?.data;
  },
  // payout-store
  async getPayoutStoreInfo() {
    const res = await apiAxiosV1.get(`/transaction/payout_store/info`);
    return PayoutStore.from(res?.data);
  },
  async getPayoutPacks({ code, page = 1, q }) {
    if (!code || !page) return null;
    const res = await apiAxiosV1.get(
      `/transaction/payout_store/payout_pack/list?page=${page}&payment_method=${code}&q=${q}`
    );
    return PayoutPack.fromAll(res?.data?.packs);
  },
  async purchasePayoutPack({ packId, paymentAddress, additional_vpa_params }) {
    const wid = StorageService.getWId();
    if (!packId || !paymentAddress || !wid) return null;
    const payload = {
      pack_id: packId,
      payment_address: paymentAddress,
      additional_vpa_params,
    };
    const res = await apiAxiosV1.post(
      `/wallet/payout_pack/wallet/${wid}/purchase`,
      payload
    );
    return res?.data;
  },
  // total transactionstore_status
  async getTotalAmountEarned() {
    const res = await apiAxiosV1.get(`/transaction/total/me`);
    return UserEarning.from(res?.data?.earned);
  },
  async getUnverifiedBalance() {
    const url = `/wallet/transactions/async/balance`;
    const res = await apiAxiosV2.get(url);
    return Payout.from(res?.data || {});
  },
};
