import { getCurrencySymbol } from '@/utils';

export class Transaction {
  constructor(obj) {
    this.errorReason = obj.errorReason;
    this.paymentAddress = obj.paymentAddress;
    this.paymentGateway = obj.paymentGateway;
    this.paymentId = obj.paymentId;
    this.paymentMethod = obj.paymentMethod;
    this.paymentStatus = obj.paymentStatus;
    this.transactionId = obj.transactionId;
    this.valueInPaise = obj.valueInPaise;
    this.valueInSikka = obj.valueInSikka;
    this.createdAt = obj.createdAt;
    this.payout = obj.payout;
  }
  static from(obj) {
    if (!obj) return null;
    return new Transaction({
      createdAt: obj.created_at_unix * 1000,
      errorReason: obj.error_reason,
      paymentAddress: obj.payment_address,
      paymentGateway: obj.payment_gateway,
      paymentId: obj.payment_id,
      paymentMethod: obj.payment_method,
      paymentStatus: obj.payment_status,
      transactionId: obj.transaction_id,
      valueInPaise: obj.value?.amount,
      valueInSikka: obj.value?.amount / 10,
      payout: {
        // amount: obj.value?.amount,
        // symbol: getCurrencySymbol(obj.value?.currency),
        amount: obj.to_currency_amount,
        symbol: getCurrencySymbol(obj.to_currency),
      },
    });
  }
  static fromAll(data) {
    if (!data) return null;
    return data.map(Transaction.from);
  }
}
