export class Earning {
  constructor(obj) {
    this.balance = obj.balance;
    this.description = obj.description;
    this.title = obj.title;
    this.offIconUrl = obj.icon_url;
    this.valueInSikka = obj.valueInSikka;
    this.createdAt = obj.createdAt;
    this.transaction_type = obj.transaction_type;
  }
  static from(obj) {
    if (!obj) return null;
    const earning = new Earning(obj);

    earning.valueInSikka = obj.value.amount;
    earning.createdAt = obj.created_at_unix * 1000;
    return earning;
  }
  static fromAll(data) {
    if (!data) return null;
    return data.map(Earning.from);
  }
}
