import { getRandomDefaultIcon } from '@/app/(Auth)/redeem/components/RedeemStore/PackCard';
import { snakeCaseToPascaleCaseWithSpace } from '@/utils';

export class PayoutStore {
  constructor(obj) {
    this.paymentMethods = obj.paymentMethods;
    this.storeStatus = obj.storeStatus;
  }
  static from(obj) {
    if (!obj) return null;
    return new PayoutStore({
      paymentMethods: PayoutMethod.fromAll(obj.payment_methods),
      storeStatus: StoreStatus.from(obj.store_status),
    });
  }
  isStoreOpen() {
    return this.storeStatus.status === 'OPEN';
  }
  updateUnlocksInSeconds(unlocksInSeconds) {
    this.storeStatus.unlocksInSeconds = unlocksInSeconds;
  }
  openStore() {
    this.storeStatus.status = 'OPEN';
  }
}
export class PayoutMethod {
  constructor(obj) {
    this.code = obj.code;
    this.iconUrl = obj.iconUrl;
    this.title = obj.title;
    this.placeholder = obj.placeholder;
  }

  isGiftCard() {
    return this.code === 'GIFT_CARD';
  }
  isTalkTime() {
    return this.code === 'TALKTIME';
  }

  static from(obj) {
    if (!obj) return null;
    return new PayoutMethod({
      code: obj.code,
      iconUrl: obj.icon_url,
      title: obj.title,
      placeholder: obj.placeholder_text,
    });
  }
  static fromAll(objs) {
    if (!objs) return null;
    return objs.map(PayoutMethod.from);
  }
}
export class StoreStatus {
  constructor(obj) {
    this.status = obj.status;
    this.unlocksInSeconds = obj.unlocksInSeconds;
  }
  static from(obj) {
    if (!obj) return null;
    return new StoreStatus({
      status: obj.status,
      unlocksInSeconds: obj.unlocks_in_sec,
    });
  }
}

// payout method packs

export class PayoutPack {
  constructor(obj) {
    this.packId = obj.packId;
    this.iconUrl = obj.iconUrl;
    this.payout = obj.payout;
    this.cost = obj.cost;
    this.selling = obj.selling;
    this.paymentMethod = obj.paymentMethod;
    this.status = obj.status;
    this.name = obj.name;
    this.description = obj.description;
    /**
     * @type {Validation}
     */
    this.validation = obj.validation;
    /**
     * @type {InputField[]}
     */
    this.inputFields = obj.inputFields;
  }
  isActive() {
    return this.status === 'ACTIVE';
  }

  getIconUrl() {
    if (this.iconUrl) return this.iconUrl;
    return this.getDefaultIcon();
  }

  getDefaultIcon() {
    return getRandomDefaultIcon(this.name);
  }

  static from(obj) {
    if (!obj) return null;
    return new PayoutPack({
      packId: obj.pack_id,
      iconUrl: obj.icon_url,
      payout: _Payout.from(obj.payout),
      cost: _Cost.from(obj.cost),
      selling: _Selling.from(obj.selling),
      paymentMethod: obj.payment_method,
      status: obj.status,
      validation: _Validation.from(obj.validation),
      inputFields: _InputField.fromAll(obj.input_fields),
      name: obj.name,
      description: obj.description,
    });
  }
  static fromAll(objs) {
    if (!objs) return null;
    return objs.map(PayoutPack.from);
  }
}
class _InputField {
  constructor(obj) {
    this.description = obj.description;
    this.fieldName = obj.fieldName;
    this.title = obj.title;
    this.paymentAddress = obj.paymentAddress;
    this.invalidErrMessage = obj.invalidErrMessage;
    this.placeholderText = obj.placeholderText;
    this.validRegex = obj.validRegex;
  }
  static from(obj) {
    if (!obj) return null;
    return new _InputField({
      description: obj.description,
      title: obj.title || snakeCaseToPascaleCaseWithSpace(obj.field_name),
      fieldName: obj.field_name,
      paymentAddress: obj.payment_address,
      invalidErrMessage: obj.invalid_err_msg,
      placeholderText: obj.placeholder_text,
      validRegex: obj.valid_regex,
    });
  }
  static fromAll(objs) {
    if (!objs) return null;
    return objs.map(_InputField.from);
  }
}
class _Payout {
  constructor(obj) {
    this.amount = obj.amount;
    this.currency = obj.currency;
  }
  static from(obj) {
    if (!obj) return null;
    return new _Payout({
      amount: obj.amount,
      currency: _Currency.from(obj.currency),
    });
  }
}

class _Currency {
  constructor(obj) {
    this.code = obj.code;
    this.symbol = obj.symbol;
  }
  static from(obj) {
    if (!obj) return null;
    return new _Currency({
      code: obj.code,
      symbol: obj.symbol,
    });
  }
}

class _Cost {
  constructor(obj) {
    this.amount = obj.amount;
    this.currency = obj.currency;
  }
  static from(obj) {
    if (!obj) return null;
    return new _Cost({
      amount: obj.amount,
      currency: obj.currency,
    });
  }
}

class _Selling {
  constructor(obj) {
    this.amount = obj.amount;
    this.currency = obj.currency;
  }
  static from(obj) {
    if (!obj) return null;
    return new _Selling({
      amount: obj.amount,
      currency: obj.currency,
    });
  }
}
class _Validation {
  constructor(obj) {
    this.placeholder = obj.placeholder;
    this.regex = obj.regex;
    this.errorMessage = obj.errorMessage;
  }
  static from(obj) {
    if (!obj) return null;
    return new _Validation({
      placeholder: obj.placeholder_text,
      regex: obj.valid_regex,
      errorMessage: obj.invalid_err_msg,
    });
  }
}
