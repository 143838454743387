// {
//     "is_capped": true,
//     "max_referrals": 0,
//     "progress": [
//       {
//         "earning": {
//           "amount": 0,
//           "currency": "string"
//         },
//         "offers_completed": 0,
//         "offers_requried": 0,
//         "user_id": "string",
//         "user_name": "string"
//       }
//     ]
//   }

import { Payout } from './offer';

export default class Referral {
  constructor(obj) {
    this.isCapped = obj.isCapped;
    this.maxReferrals = obj.maxReferrals;
    this.progress = obj.progress;
  }

  static from(obj) {
    return new Referral({
      isCapped: obj.is_capped,
      maxReferrals: obj.max_referrals,
      progress: ReferralProgress.fromAll(obj.progress),
    });
  }
}

export class ReferralProgress {
  constructor(obj) {
    this.earning = obj.earning;
    this.offersCompleted = obj.offersCompleted;
    this.offersRequried = obj.offersRequried;
    this.userId = obj.userId;
    this.userName = obj.userName;
  }

  static fromJson(obj) {
    return new ReferralProgress({
      earning: Payout.from(obj.earning),
      offersCompleted: obj.offers_completed,
      offersRequried: obj.offers_requried,
      userId: obj.user_id,
      userName: obj.user_name,
    });
  }

  static fromAll(obj) {
    if (!obj) return null;
    return obj.map(ReferralProgress.fromJson);
  }
}
