import { apiAxiosV1 } from '@/configs/axios';
import { Profile, TrackingData, User } from '@/model/Users';
import { StorageService } from '@/service/StorageService';
import { AndroidService } from './Android';
import { API_END_POINT } from '@/configs/config';

export const UserService = {
  async getUser() {
    const url = `user/profile/me`;

    const res = await apiAxiosV1.get(url);
    return User.from(res?.data || null);
  },
  async updateUser(data) {
    const url = `user/profile/update`;
    const payload = User.toHttpObject(data);
    const res = await apiAxiosV1.put(url, payload);
    return User.from(res?.data || null);
  },
  async getProfile() {
    const url = `/user/profile/me`;

    const res = await apiAxiosV1.get(url);

    return User.from(res?.data || null);
  },
  async profileInit(data) {
    const url = `basket/profile/init`;
    const res = await apiAxiosV1.post(url, data);
    return Profile.from(res?.data || null);
  },
  async getCountyCode() {
    const url = `${API_END_POINT}/v3/geolocate/country_code/get`;
    const res = await apiAxiosV1.get(url);
    return res?.data.code;
  },
  async getTotalOfferTried() {
    const url = `user_offer/total_offers/me`;
    const res = await apiAxiosV1.get(url);
    return res?.data?.total_offers_tried;
  },
  storeFcmToken: async fcmToken => {
    const url = `user/profile/fcm_token/update`;
    const res = await apiAxiosV1.put(url, { fcm_token: fcmToken });
    return res?.data;
  },
};
