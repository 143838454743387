import { getCurrencySymbol } from '@/utils';
export class UserEarning {
  constructor(obj) {
    this.amount = obj.amount;
    this.currency = obj.currency;
    this.symbol = obj.symbol;
  }
  static from(obj) {
    if (!obj) return null;
    return new UserEarning({
      amount: obj.amount,
      currency: obj.currency,
      symbol: getCurrencySymbol(obj.currency),
    });
  }
}
